import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
//import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
/* import { 
  sanitizeHtmlContent, 
  //truncateText 
} from '../utils/utils' */

import { 
  Container,
  Segment,
  Item,
  Button,
  Header,
  Input,
  Icon,
  Divider,
} from 'semantic-ui-react';

dayjs.extend(utc)

const SearchPressRelease = (props) => {
  //console.debug('SearchPressRelease - props', props);

  const allPressReleaseQuery = useStaticQuery(graphql`
    query AllPressReleaseQuery {
      allPressRelease(limit: 50) {
        edges {
          node {
            contentItemId
            contentType
            displayText
            publishedUtc
            publicationDate
            path
          }
        }
      }
    }
  `);
  const { edges } = allPressReleaseQuery.allPressRelease
  //console.debug('SearchPressRelease - edges', edges);

  const [filterState, setFilterState] = useState({
    query: '',
    results: [],
  });

  const handleFilterSelect = (e) => {
    //console.debug('SearchPressRelease - handleFilterSelect', {e});
    const value = e.target.value;
    if (value.length >= 3) {
      if (value !== filterState.query) {
        const filteredContent = edges.filter((pressRelease) => pressRelease.node.displayText.toLowerCase().includes(value.toLowerCase()));
        //console.debug('handleFilterSelect - filteredContent', filteredContent);
        setFilterState({query: value, results: filteredContent});
      }
    } else {
      setFilterState({query: value, results: []});
    }
  }
  //console.debug('SearchPressRelease - filterState', filterState);

  const handleFilterClear = () => {
    //console.debug('SearchPressRelease - handleFilterClear');
    setFilterState({query: '', results: []});
  }

  return (
    <>
      <Segment basic className={'search'} style={{ padding: '0', marginTop: '25px', }}>
        <Container>
          <Input size={'big'}
            icon={filterState.query ? <Icon name='remove' link onClick={()=>handleFilterClear()} /> : <Icon name='search' />}
            name='filterQuery'
            placeholder='Search Press Releases...'
            value={filterState.query}
            onChange={(e)=>handleFilterSelect(e)}
          />
        </Container>
        {filterState.query !== null && filterState.results.length !== 0 ?
          <Segment placeholder className={'press-release-list'} style={{ marginTop: '25px', marginBottom: '25px', paddingTop: '50px', paddingBottom: '50px', border: 'none' }}>
            <Container>
              <h2 style={{color: '#230859', }}>Search Results</h2>
              <Divider />
              <Item.Group relaxed={'very'} divided style={{ minHeight: '100px', maxHeight: '600px', overflowY: 'auto', }}>
                {filterState.results.map((pressRelease, i) => {
                  const { displayText, publishedUtc, publicationDate, path, } = pressRelease.node;
                  const dateRender = publicationDate ? dayjs.utc(publicationDate).format('MMMM DD, YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM DD, YYYY') : null;
                  const datePath = publicationDate ? dayjs.utc(publicationDate).format('MMMM-DD-YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM-DD-YYYY') : null;
                  return (
                    <Item key={i} style={{ paddingRight: '15px', }}>
                      <Item.Content>
                        <Item.Header as={Link} to={path ? ('/press-releases/'+datePath+'/'+path) : null}>
                          <div className={'site-content-headline'}>
                            <Header as={'h3'} textAlign={'left'}>{displayText}</Header>
                          </div>
                        </Item.Header>
                        <Item.Extra>{dateRender}</Item.Extra>
                      </Item.Content>
                      <Button className={'site-content-button outline'}
                        style={{ color: '#230859', borderColor: '#230859' }}
                        as={Link}
                        to={path ? ('/press-releases/'+datePath+'/'+path) : null}
                        icon={'arrow right'}
                        labelPosition={'right'}
                        content={'Read More'}
                      />
                    </Item>
                  )
                })}
              </Item.Group>
            </Container>
          </Segment>
        :
          null
        }
      </Segment>
    </>
  )
}

export default SearchPressRelease

import * as React from 'react'
import { graphql, Link, navigate } from "gatsby"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Seo from '../components/seo'
import GatsbyGoogleTag from "../components/gatsby-google-tag"

import TritonDefaultLayout from '../layout/default-layout'
import SectionHero from '../components/sections/SectionHero/SectionHero'
import SearchPressRelease from '../components/search/search-pressRelease'

import { 
  Container,
  Segment,
  Item,
  Button,
  Header,
  Pagination,
} from 'semantic-ui-react';

dayjs.extend(utc)

export const Head = ({ location, }) => {
  //console.debug('pages - head', { location, params, data, pageContext });
  // handle page level seo content
  const seo = {
    pageTitle: 'All Press Releases',
    pageDescription: null,
  }
  return (
    <>
      <Seo pagePath={location.pathname} title={seo.pageTitle} description={seo.pageDescription} />
      <GatsbyGoogleTag />
    </>
  )
}

const PaginationIndexPressRelease = (props) => {
  //console.debug('PaginationIndexPressRelease - props', props);

  if (typeof window !== 'undefined') {
    document.body.id = 'pages-pagination-index-press-release';
  }

  const {
    edges,
  } = props.data.allPressRelease;

  const { humanPageNumber, numberOfPages, } = props.pageContext;

  const handlePaginationChange = (e, { activePage }) => {
    //console.debug('PaginationIndexPressRelease - handlePaginationChange', activePage);
    navigate('/about/newsroom/press-releases/'+(activePage !== 1 ? activePage : ''));
  }

  return (
    <>
      {/* <Seo title={'All Press Releases'} /> */}
      <TritonDefaultLayout subpage={'press-release-index'}>
        <SectionHero 
          commonPageSection={{
            "dOMID": "top",
            "textColor": "white",
            "textAlign": "left",
            "backgroundColor": "linear-gradient(225deg, rgb(254, 125, 76) 0%, rgb(224, 111, 126) 11%, rgb(148, 60, 215) 31%, rgb(75, 0, 161) 53%, rgb(93, 44, 208) 72%, rgb(18, 175, 222) 100%)",
          }}
          contentCollection={{
            contentItems: [
              {
                "contentItemId": "4hp70xdbw2ycwvfvhrdvhvmtcb",
                "contentType": "ContentHeadline",
                "displayText": "News Item Page Headline",
                "variationType": "h1",
                "textAlign": "center",
                "preheader": "Newsroom",
                "content": {
                  "html": "Press Releases"
                },
                "subheader": null,
                "maxWidth": "100%"
              }
            ]
          }} 
        />
        <SearchPressRelease />
        <Segment basic className={'press-release-list'}>
          <Container>
            <Item.Group relaxed={'very'} divided>
              {edges && edges.length !== 0 && edges.map((pressRelease, i) => {
                const { displayText, publishedUtc, publicationDate, path, } = pressRelease.node;
                const dateRender = publicationDate ? dayjs.utc(publicationDate).format('MMMM DD, YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM DD, YYYY') : null;
                const datePath = publicationDate ? dayjs.utc(publicationDate).format('MMMM-DD-YYYY') : publishedUtc ? dayjs.utc(publishedUtc).format('MMMM-DD-YYYY') : null;
                return (
                  <Item key={i}>
                    <Item.Content>
                      <Item.Header as={Link} to={path ? ('/press-releases/'+datePath+'/'+path) : null}>
                        <div className={'site-content-headline'}>
                          <Header as={'h3'} textAlign={'left'}>{displayText}</Header>
                        </div>
                      </Item.Header>
                      <Item.Extra>
                        {dateRender}
                      </Item.Extra>
                    </Item.Content>
                    <Button className={'site-content-button outline'}
                      style={{ color: '#230859', borderColor: '#230859' }}
                      as={Link}
                      to={path ? ('/press-releases/'+datePath+'/'+path) : null}
                      icon={'arrow right'}
                      labelPosition={'right'}
                      content={'Read More'}
                    />
                  </Item>
                )
              })}
            </Item.Group>
            <Segment basic textAlign={'center'} className={'pagination-wrapper'}>
              <Pagination size={'large'} aria-label={'Pagination Navigation'}
                activePage={humanPageNumber}
                totalPages={numberOfPages}
                onPageChange={handlePaginationChange}
                boundaryRange={1}
                siblingRange={0}
                ellipsisItem={true ? undefined : null}
                firstItem={null}
                lastItem={null}
                // firstItem={true ? undefined : null}
                // lastItem={true ? undefined : null}
                prevItem={true ? undefined : null}
                nextItem={true ? undefined : null}
              />
            </Segment>
          </Container>
        </Segment>
      </TritonDefaultLayout>
    </>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allPressRelease(
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          contentItemId
          contentType
          displayText
          publishedUtc
          publicationDate
          path
        }
      }
    }
  }
`;

export default PaginationIndexPressRelease
